<template>
    <div class="row-container">
        <template v-if="!titleSlot">
            <div class="parent-info divBadge" :class="{'active': isOpen}">
                <div class="title-container">
                    <div><span style="min-width: 280px;">{{ title }}</span></div>
                </div>
                <div  class="">
                    <div><span class="badge">{{ formatNum(monto) }}</span></div>
                </div>
                <v-spacer></v-spacer>
                <i v-if="isOpen" class="material-icons pr-4" @click="isOpen = false">keyboard_arrow_up</i>
                <i v-else class="material-icons pr-4 " :class="{'mt-icon' : image}" @click="isOpen = true">keyboard_arrow_down</i>
            </div>
        </template>

        <template v-else>
            <slot name="title" v-if="titleSlot"></slot>
        </template>

        <transition name="slide-fade">
            <div class="child-info" v-if="isOpen">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'expandable-item',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        image: {
            type: String,
            required: false,
            default: ''
        },
        monto:{
            type: [String, Number],
            required: false,
            default: null
        }
    },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        titleSlot() {
            if(this.$scopedSlots) {
                return this.$scopedSlots.title;
            }

            return null;
        },
    },
    methods: {
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
    },
}
</script>

<style scoped>
    .child-info{
        width: 100%;
        background-color: #FFFFFF;
    }
    .row-container {
        display:initial;
    }

    .row-container .parent-info {
        display:flex;
        align-items: center !important;
        padding: 10px 0px;
        background-color: #FFFFFF;
    }

    .row-container .parent-info.active {
        background-color: #F9F9F9 !important;
    }

    div.title-container { 
        display: inline-flex !important;
        align-items: center !important;
        padding-left: 35px !important;
    }

    .image-profile {
        height: 40px;
        width: 40px;
        border-radius: 50%;

        margin-right: 15px;
    }

    .row-container .parent-info div span {
        float: left;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #101C5A;
    }

    .row-container .parent-info i {
        float: right;

        color: #101C5A;
        cursor: pointer;
    }

    .row-container .parent-info i.mt-icon {
        /* margin-top: 6px; */
    }

    /**
    * TRANSITION EFFECT
    */
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition-duration: 0.8s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .slide-fade-enter,
    .slide-fade-leave-active {
    opacity: 0
    }

    .badge{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #1E2245 !important;
        background-color: #FFFFFF;
        padding:7px 15px;
        border-radius: 8px;
        border: 1px solid #E0E0E0;

    }
    .divBadge{
        align-content: center !important;
    }
</style>