<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tablaFondeo"
                        :tableName="'Fondeo'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave_tipo_nomina}} - {{item.descripcion}}</div></td>
                                <td><div>{{item.numero_periodo}}</div></td>
                                <td><div>{{dateFormat(item.fecha_de_pago, 'tabla')}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('ver', item)" small>
                                                <v-icon class="iconoDelgadoBoton" v-text="'$visibility_outline'"></v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Ver</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <perfect-scrollbar style="background:#FFF">
                    <v-card>
                        <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent" style="float: right;">
                            <v-btn
                                @click="cerrarModal()" 
                                small
                                icon
                                text
                                :ripple="false" 
                                class="v-btn-cerrar mr-2 mt-2"
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        </div>
                        <v-card-title class="justify-center card_titulo pb-4">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                            
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="py-0 my-0 informacionGeneral">
                                    <v-row class="px-9 pt-8 pb-3">
                                        <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                            <p class="informacionGeneralTitulo">Total global de bancos:</p>
                                        </v-col> 
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="informacionGeneralText" v-if="montoNomina>0">{{formatNum(montoNomina)}}</p>
                                        </v-col>
                                    </v-row>
                                </v-row>
                                <v-row class="mt-0" >
                                    <simple-table
                                        class="tablaWidth"
                                        :rows="itemsBancos"
                                        :columns="headersTableBancos"
                                        :perPage="[100]"
                                        shortPagination
                                        :modalTable="true"
                                        :showPagination="false"
                                    >
                                        <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                            <tr :key="item.id" v-for="(item, index) in data">
                                                <td class="pa-0">
                                                    <expandable-item :title="item.banco_nombre" :monto="item.monto">
                                                        <template slot="content">
                                                            <table class="tbl-child" width="100%">
                                                                <tbody>
                                                                    <template>
                                                                        <tr v-for="(empleado, index2) in item.empleados" :key="index2">
                                                                            <td>
                                                                                <v-list  color="transparent" class="py-0 my-0">
                                                                                    <v-list-item class="py-0 my-0 px-0">
                                                                                        <v-list-item-avatar  class="py-0 my-0">
                                                                                            <v-img  :src="urlImg+empleado.empleado.foto"></v-img>
                                                                                        </v-list-item-avatar>
                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-list-item-content v-on="on" class="ml-0 py-0 my-0">
                                                                                                    <v-list-item-title class="tblPrincipal text-wrap">{{ empleado.empleado.nombre_completo }}</v-list-item-title>
                                                                                                </v-list-item-content>
                                                                                            </template>
                                                                                            <span>{{ empleado.empleado.nombre_completo }}</span>
                                                                                        </v-tooltip>  
                                                                                    </v-list-item>
                                                                                </v-list>
                                                                            </td>
                                                                            <v-spacer></v-spacer>
                                                                            <td class="pt-4 pl-4"><p class="text">{{formatNum(empleado.monto)}}</p></td>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </template>
                                                    </expandable-item>
                                                    
                                                    <hr style="border-bottom: 1px solid #EFF1FB;" v-if="index < data.length - 1" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </simple-table>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';
import apiFondeo from '@/api/nominas/Fondeo';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItemFondeo.vue';
import GetUrlImg from "@/plugins/Urlimagen";


export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        ExpandableItem,
    },
    data() {
        return {
            url         : "calculo-nomina/find",
            columns     : [
                {
                    label: 'Tipo nómina',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Periodo',
                    name: 'numero',
                    filterable: false
                },
                {
                    label: 'Fecha de pago',
                    name: 'fecha_de_pago',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"desc",
                cliente_id: null,
                acumulado : true,
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : "",
            urlImg          : null,
            datosLogin      : null,
            rol             : null,
            cliente_value   : null,
            headersTableBancos   : [
                { label: "Lista de bancos", align:'center',   name: "banco_nombre",    filterable: false },
            ],
            itemsBancos     : [],
            montoNomina     : 0,

        }
    },
    methods: {
        /**
         * @method abrirModal Este método realiza la acción para abrir un modal.
         * @description Este método realiza la acción para abrir un modal dependiento de la acción que se le envie, la acción que se envia es 'ver'
         * @param {string} accion Esta variable puete tomar el valor de 'ver'.
         * @param {Object} data Esta variable es un objeto, son los datos de una nomina acumulada.
        */
        async abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            this.tituloModal    = "Consultar bancos";
            this.imagen         = "/static/modal/fondeoBancos.png";
            let parametros = { "nomina_id":data.id };
            this.montoNomina = 0;
            await apiFondeo.getFondeo(parametros).then((response) => {
                this.itemsBancos = response.data;
                this.itemsBancos.forEach(item => {
                    this.montoNomina = this.montoNomina + item.monto;
                })
            })
            .catch (err => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            })

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValues() {
            
        },
        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte un fecha de formato AAAA-MM-DD => DD/MM/AAAA. 
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha, tipo) {
            let fecha2 = fecha.split("-");
            if(tipo == 'modal'){
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }else{
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
    },
    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created(){
        this.urlImg = GetUrlImg.getURL();
        this.datosLogin = this.$session.get('usuario');
        this.rol = this.datosLogin.rol.name;
        if (this.rol != "root") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_value = this.datosLogin.cliente_id;
        }
        if(this.rol == 'root'){
            this.getClientes();
        }
    },
    /**
     * @description update(). Se ejecuta cada vez que captura un cambio en el DOM. 
     * Se utilizo para reajustar el tamaño de la tabla al abrir el modal.
    */
    updated() { 
        let tabla = document.querySelector('#tableScrollSimple');

        if(tabla){
            document.querySelector('#tableScrollSimple tbody').style.minWidth = "400px"
        }
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #4F4F4F;
    }

    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }
</style>
